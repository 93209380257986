import React, { useState, useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import {
  PDFViewer as DefaultViewer,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { useFormState, useForm } from 'react-final-form';

import { PaidTemplate, UnpaidTemplate } from './template';
import { env } from '#services/constants';

const PDFViewer = (props) => {
  return useMemo(
    () => (
      <DefaultViewer key={Math.random()} width="800px" height="1000px">
        <UnpaidTemplate {...props} />
      </DefaultViewer>
    ),
    [props],
  );
};

const ReceiptGenerator = () => {
  const [isReady, setIsReady] = useState(false);

  const { subscribe } = useForm();
  const { values: formValues } = useFormState();

  useEffect(() => {
    const unsubscribe = subscribe(() => setIsReady(false), { values: true });

    return () => {
      unsubscribe();
    };
  }, [subscribe]);

  return (
    <Grid container direction="column" alignItems="center" spacing={6}>
      {!env.IS_STATIC && (
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: 16 }}
            onClick={() => setIsReady(true)}
          >
            Rafraîchir la prévisualisation
          </Button>

          {isReady && (
            <>
              <Button
                color="primary"
                variant="contained"
                style={{ marginRight: 16 }}
              >
                <PDFDownloadLink
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  document={<UnpaidTemplate {...formValues} />}
                  fileName={`facture-${get(formValues, 'number')}.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <CircularProgress size={17} color="secondary" />
                    ) : (
                      'Générer la facture'
                    )
                  }
                </PDFDownloadLink>
              </Button>

              <Button color="primary" variant="contained">
                <PDFDownloadLink
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  document={<PaidTemplate {...formValues} />}
                  fileName={`facture-${get(formValues, 'number')}-payee.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <CircularProgress size={17} color="secondary" />
                    ) : (
                      'Générer la facture payée'
                    )
                  }
                </PDFDownloadLink>
              </Button>
            </>
          )}
        </Grid>
      )}

      {isReady && !env.IS_STATIC && (
        <Grid item>
          <PDFViewer {...formValues} />
        </Grid>
      )}
    </Grid>
  );
};

ReceiptGenerator.propTypes = {};

export default ReceiptGenerator;
