import React, { useState, useEffect } from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import classNames from 'classnames';
import { useFormState, useForm } from 'react-final-form';
import moment from 'moment';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons';
import {
  Typography,
  Grid,
  Box,
  Paper,
  Tabs,
  Tab,
  makeStyles,
} from '@material-ui/core';
import {
  Edit,
  FormWithRedirect,
  TextInput,
  NumberInput,
  ArrayInput,
  BooleanInput,
  SimpleFormIterator,
  Toolbar,
} from 'react-admin';
import { DateInput } from '#ui/DateInput';

import ReceiptTitle from './receipt-title';
import ReceiptGenerator from './generator';
import { dateFieldOptions } from './utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, 4),
  },

  sectionTitle: {
    marginBottom: theme.spacing(3),
  },

  input: {
    marginRight: theme.spacing(2),
  },

  addressInput: {
    width: 300,
  },

  simpleFormIterator: {
    '& section': {
      display: 'flex',
    },
  },
}));

const CustomItemField = ({ source }) => {
  const { values: formValues } = useFormState();
  const { price = 0, quantity = 0 } = get(formValues, source, {});

  return (
    <Box mt={3}>
      <Typography variant="body1">
        Total&nbsp;: <b>{price * quantity}</b>
      </Typography>
    </Box>
  );
};

const TabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`wrapped-tabpanel-${index}`}
    aria-labelledby={`wrapped-tab-${index}`}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

const ExpiryDateInput = ({ source, inputProps }) => {
  const { values: formValues } = useFormState();
  const { change } = useForm();

  const issueDate = get(formValues, 'issue_date', {});
  const [prevIssueDate, setPrevIssueDate] = useState(issueDate);

  useEffect(() => {
    if (!isEmpty(issueDate) && !isEqual(issueDate, prevIssueDate)) {
      setPrevIssueDate(issueDate);
      change('expiry_date', moment(issueDate).add(3, 'weeks').toDate());
    }
  }, [issueDate, change, prevIssueDate]);

  return (
    <DateInput
      label="Date d'expiration"
      source="expiry_date"
      {...dateFieldOptions}
      {...inputProps}
    />
  );
};

const EditForm = (props) => {
  const [tabValue, setTabValue] = useState('client');
  const classes = useStyles();

  const inputProps = {
    className: classes.input,
  };

  const sectionTitleProps = {
    variant: 'h4',
    className: classes.sectionTitle,
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <FormWithRedirect
      initialValues={{
        issue_date: Date.now(),
        expiry_date: moment().add(3, 'weeks').toDate(),
      }}
      render={(formProps) => (
        <div>
          <Paper className={classes.paper}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography {...sectionTitleProps}>Information</Typography>

                <NumberInput
                  label="Nº Facture"
                  source="number"
                  textAlign="left"
                  {...inputProps}
                />

                <DateInput
                  label="Date d'émission"
                  source="issue_date"
                  {...dateFieldOptions}
                  {...inputProps}
                />

                <ExpiryDateInput inputProps={inputProps} />

                <NumberInput
                  label="Acompte"
                  source="deposit"
                  textAlign="left"
                  {...inputProps}
                />

                <BooleanInput label="Payé" source="receipt_totally_paid" />
              </Grid>

              <Grid item>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab value="client" label="Client" />
                  <Tab value="items" label="Articles" />
                </Tabs>

                <TabPanel value={tabValue} index="client">
                  <TextInput
                    label="Nom du client"
                    source="client.name"
                    {...inputProps}
                  />
                  <TextInput
                    label="Adresse du client"
                    source="client.address"
                    {...inputProps}
                    className={classNames(classes.input, classes.addressInput)}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index="items">
                  <ArrayInput source="items">
                    <SimpleFormIterator
                      className={classes.simpleFormIterator}
                      addButton={
                        <AddCircleOutlineIcon style={{ cursor: 'pointer' }} />
                      }
                      removeButton={
                        <HighlightOffIcon style={{ cursor: 'pointer' }} />
                      }
                    >
                      <TextInput
                        label="Désignation"
                        source="name"
                        {...inputProps}
                      />
                      <NumberInput
                        label="Prix unitaire"
                        source="price"
                        {...inputProps}
                      />
                      <NumberInput
                        label="Quantité"
                        source="quantity"
                        {...inputProps}
                      />
                      <CustomItemField />
                    </SimpleFormIterator>
                  </ArrayInput>
                </TabPanel>
              </Grid>
            </Grid>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
            />
          </Paper>

          <Paper className={classes.paper}>
            <Typography {...sectionTitleProps}>Prévisualisation</Typography>
            <ReceiptGenerator />
          </Paper>
        </div>
      )}
      {...props}
    />
  );
};

const ReceiptEdit = (props) => (
  <Edit title={<ReceiptTitle />} {...props} component="div">
    <EditForm />
  </Edit>
);

export default ReceiptEdit;
