import { createMuiTheme } from '@material-ui/core/styles';
import { frFR } from '@material-ui/core/locale';

import red from '@material-ui/core/colors/red';

const colors = {
  lightBlue: '#365c6d',
  blue: '#4f78da',
  darkBlue: '#1b2533',
  yellow: '#e2c330',
  green: '#14c39c',
  lightPurple: '#cd9589',
  purple: '#b41d6c',
  gray: '#32363e',
};

export const theme = createMuiTheme(
  {
    palette: {
      primary: { main: colors.green },
      secondary: { main: colors.gray },
      error: red,
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    typography: {},
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 100,
        },
      },
      MuiTextField: {},
    },
  },
  frFR,
);
