export const activeEnv =
  process.env.REACT_APP_ENV || process.env.NODE_ENV || 'production';

export const env = {
  IS_DEV: activeEnv === 'development',
  IS_STAGING: activeEnv === 'staging',
  IS_PROD: activeEnv === 'production',
  IS_TEST: activeEnv === 'test',
  IS_STATIC: typeof document === 'undefined',
  value: activeEnv,
};

// Firebase
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
