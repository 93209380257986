import moment from 'moment';
import { sumBy } from 'lodash';

export const receiptStatus = {
  EXPIRED: 'EXPIRED',
  PAID: 'PAID',
  ACTIVE: 'ACTIVE',
};

export const dateFieldOptions = {
  locales: 'fr-FR',
  options: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
};

export const formatPrice = (number) =>
  `${parseFloat(number).toLocaleString('de-DE').replace('.', ' ')} CFA`;

export const getTotalNumberOfItems = ({ items }) => sumBy(items, 'quantity');

export const getReceiptTotalPrice = ({ items }) =>
  (items || []).reduce(
    (total, { price, quantity }) => total + price * quantity,
    0,
  );

export const getReceiptStatus = (record) => {
  const { expiry_date, receipt_totally_paid } = record;
  if (receipt_totally_paid) {
    return receiptStatus.PAID;
  }

  if (moment(expiry_date).isBefore()) {
    return receiptStatus.EXPIRED;
  }

  return receiptStatus.ACTIVE;
};
