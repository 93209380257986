import React from 'react';
import { Layout as RaLayout } from 'react-admin';

import AppBar from './app-bar';
import Sidebar from './sidebar';
// import MyAppBar from './MyAppBar';
// import MySidebar from './MySidebar';
// import MyMenu from './MyMenu';
// import MyNotification from './MyNotification';

const Layout = (props) => (
  <RaLayout
    {...props}
    appBar={AppBar}
    sidebar={Sidebar}
    // menu={MyMenu}
    // notification={MyNotification}
  />
);

export default Layout;
