import React, { Children } from 'react';
import { Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useSidebarStyles = makeStyles((theme) => ({
  drawerPaper: {
    backgroundColor: '#FBFCFA',
    marginRight: theme.spacing(3),
  },
}));

const MySidebar = ({ children, ...props }) => {
  const classes = useSidebarStyles();

  const mappedChildren = (
    <>
      {Children.map(children, (child) => {
        // const element = cloneElement(child, {
        //   isPlaying: child.props.title === currentPlayingTitle,
        // });
        // console.log('child', child);
        // console.log('element', element);

        return child;
      })}
    </>
  );

  return <Sidebar classes={classes} children={mappedChildren} {...props} />;
};

export default MySidebar;
