import React, { cloneElement } from 'react';
import { Chip, Typography } from '@material-ui/core';
import { get } from 'lodash';

import {
  Datagrid,
  List,
  Filter,
  TextField,
  NumberField,
  DateField,
  FunctionField,
  NumberInput,
  TextInput,
  EditButton,
  sanitizeListRestProps,
  TopToolbar,
  CreateButton,
  ExportButton,
  useCreate,
  useQueryWithStore,
  useListContext,
} from 'react-admin';

import ReceiptEdit from './receipt-edit';
import {
  getTotalNumberOfItems,
  getReceiptStatus,
  getReceiptTotalPrice,
  receiptStatus,
  dateFieldOptions,
  formatPrice,
} from './utils';

const ReceiptFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nom client" source="client.name" alwaysOn />
    <TextInput label="Adresse client" source="client.address" />
    <NumberInput label="Nº Facture" source="number" />
  </Filter>
);

// Use https://marmelab.com/react-admin/CreateEdit.html#transform to enable `status` shorting

const ListActions = ({ className, exporter, filters, maxResults, ...rest }) => {
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();

  const [create] = useCreate('receipts');
  const { data } = useQueryWithStore({
    type: 'getList',
    resource: 'receipts',
    payload: {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'number', order: 'DESC' },
    },
  });

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      <span
        onClick={() =>
          create({
            payload: { data: { number: get(data, '0.number', 0) + 1 } },
          })
        }
      >
        <CreateButton basePath="" to="" label="Créer" />
      </span>

      <ExportButton
        label="Exporter"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const ListTitle = () => <span></span>;

export const ReceiptList = (props) => (
  <List
    {...props}
    filters={<ReceiptFilter />}
    actions={<ListActions />}
    title={<ListTitle />}
    sort={{ field: 'number', order: 'DESC' }}
  >
    <Datagrid>
      <NumberField label="Nº Facture" source="number" textAlign="left" />
      <TextField label="Nom du client" source="client.name" />
      <TextField label="Adresse du client" source="client.address" />
      <DateField
        label="Date d'émission"
        source="issue_date"
        {...dateFieldOptions}
      />
      <FunctionField
        label="État"
        render={(record) => {
          const status = getReceiptStatus(record);

          switch (status) {
            case receiptStatus.EXPIRED:
              return <Chip label="Expiré" style={{ color: 'red' }} />;
            case receiptStatus.PAID:
              return <Chip label="Payé" style={{ color: '#15c500' }} />;
            default:
              return <Chip label="En cours" />;
          }
        }}
      />
      <FunctionField
        label="Prix Total"
        render={(record) => {
          return (
            <Typography variant="body2">
              {formatPrice(getReceiptTotalPrice(record))}
            </Typography>
          );
        }}
      />
      <FunctionField
        label="Sacs"
        render={(record) => {
          return (
            <Typography variant="body2" align="center">
              {getTotalNumberOfItems(record)}
            </Typography>
          );
        }}
      />

      <EditButton label="" />
    </Datagrid>
  </List>
);

export { ReceiptEdit };

// export const ReceiptCreate = (props) => (
//   <Create {...props}>
//     <SimpleForm>
//       <TextInput source="title" />
//       <RichTextInput source="body" />
//       <ReferenceInput label="Comment" source="title" reference="comments">
//         <SelectInput optionText="title" />
//       </ReferenceInput>
//       <FileInput source="file" label="File" accept="application/pdf">
//         <FileField source="src" title="title" />
//       </FileInput>
//     </SimpleForm>
//   </Create>
// );
