import React from 'react';
import { Admin, Resource } from 'react-admin';
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from 'react-admin-firebase';
import ReceiptIcon from '@material-ui/icons/Receipt';

import { ReceiptList, ReceiptEdit } from '#resources/Receipts';
import Layout from '#components/Layout';
import CustomLoginPage from './custom-login-page';
import { FIREBASE_CONFIG } from '#services/constants';
import { theme } from '#services/theme';
import avatar from '#assets/avatar.jpg';
import './app.css';

const options = {};

const dataProvider = FirebaseDataProvider(FIREBASE_CONFIG, options);
const authProvider = FirebaseAuthProvider(FIREBASE_CONFIG, options);

const App = () => (
  <Admin
    layout={Layout}
    theme={theme}
    loginPage={CustomLoginPage}
    dataProvider={dataProvider}
    authProvider={{
      ...authProvider,
      getIdentity: () => {
        return Promise.resolve({ id: '', fullName: 'Zeinabou', avatar });
      },
    }}
  >
    <Resource
      options={{ label: 'Factures' }}
      name="receipts"
      icon={ReceiptIcon}
      list={ReceiptList}
      edit={ReceiptEdit}
    />
  </Admin>
);

export default App;
